import React from 'react';
import type { NextPage } from 'next';

import { useTranslate } from 'hooks/useTranslations';
import { Page } from 'ui/components/Page';
import { PageHead } from 'ui/components/PageHead';
import { SanityContentSlot } from 'ui/components/SanityContentSlot';
import { InjectedScript } from 'ui/elements/InjectedScript';
import { avoidEmptyProperties } from 'utils/object';
import { HomePageContent, HomePageQuery } from 'groq/pages/HomePage';
import { useGroqQuery } from 'hooks/useGroqQuery';
import { useSiteConfig } from 'hooks/useSiteConfig';
import { getMappedCountryCode } from 'utils/locale';
import { dyContext } from 'utils/dynamicYield';
import { useDynamicYieldContext } from 'hooks/useDynamicYieldContext';
import { regionBasedSchema } from 'utils/regionBasedSchema';

const Home: NextPage = () => {
  const [pageQuery] = useGroqQuery({
    operationName: 'HomePage',
    query: HomePageQuery,
  });
  const { countryCode } = useSiteConfig();

  useDynamicYieldContext({ type: dyContext.HOMEPAGE });

  const mappedCountryCode =
    getMappedCountryCode(countryCode) || countryCode.toLowerCase();

  const page = pageQuery.data?.content as null | HomePageContent;
  const pageTitle = page?.seo?.pageTitle;
  const pageDescription = page?.seo?.pageDescription;
  const t = useTranslate();
  const schema = regionBasedSchema(mappedCountryCode, t);

  return (
    <React.Fragment>
      <PageHead title={pageTitle} description={pageDescription} />
      <InjectedScript
        type="application/ld+json"
        script={JSON.stringify(avoidEmptyProperties(schema))}
      />
      <Page query={pageQuery}>
        <SanityContentSlot
          items={page?.slots}
          slotId="slots"
          documentId={page?._id}
          fullscreen
          headersContent={page?.headersContent}
        />
      </Page>
    </React.Fragment>
  );
};

export default Home;
